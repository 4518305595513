import React from 'react'
import styled from 'styled-components'
import { DefaultLayout } from '../components/Layout'
import { LinkedinLogo, MailIcon } from '../components/Icons'

const Telephone = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: inherit;
  font-size: calc(16px + (18 - 16) * (100vw - 400px) / (1000 - 400));
  line-height: 1.8;
  margin: 1em o;
`

const ExternalLink = styled.a`
  text-align: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  margin: 2em 0;
  svg {
    margin-right: 15px;
  }
  @media screen and (min-width: 1000px) {
    text-align: right;
  }
  :hover,
  :active,
  :visited,
  :focus {
    text-decoration: none;
  }
`

const Content = styled.div`
  margin: 2em;
`
const ContactPage = () => (
  <DefaultLayout title="Contact Milaco">
    <h1>Contact</h1>
    <h3>We're here to help...</h3>
    <Content>
      <div>
        <h5>Registered Office</h5>
        <p>
          Unit 1 Barons Court, <br />
          Graceways, <br />
          Blackpool <br />
          FY4 5GP
        </p>
        <Telephone href="tel:+448003689329">T: 0800 368 9329</Telephone>
      </div>
      <div>
        <ExternalLink target="blank" aria-label="Follow Milaco on Linkedin" href="mailto:info@milaco.co.uk">
          <MailIcon fill="#2B69D8" width="25px" />
          info@milaco.co.uk
        </ExternalLink>
        <ExternalLink
          target="blank"
          aria-label="Follow Milaco on Linkedin"
          href="https://www.linkedin.com/company/milaco/"
        >
          <LinkedinLogo fill="#2B69D8" width="150px" />
        </ExternalLink>
      </div>
    </Content>
  </DefaultLayout>
)

export default ContactPage
